/* eslint-disable */
export interface FirebaseConfiguration {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface IOSConfiguration {
  displayName: string;
  bundleId: string;
  teamId: string;
  iosAppStoreId: string,
  firebaseApiKey: string;
  firebaseAppId: string;
  firebaseClientId: string;
  codePushDeploymentKey: string;
}

export interface AndroidConfiguration {
  displayName: string;
  packageName: string;
  firebaseAppId: string;
  firebaseClientId: string;
  firebaseApiKey: string;
  codePushDeploymentKey: string;
}

export interface EnvironmentConfiguration {
  built: number;
  appVersion: string;
  eulaVersion: string;
  environment: string;
  production: boolean;
  bcc: string;
  sendgridFrom: string;
  subjectPrefix: string;
  siteOrigin: string;
  siteOriginWww: string;
  cloudFunctionOrigin: string;
  dynamicLinkOrigin: string;
  appengineOrigin: string;
  gcpLocation: string;
  firebase: FirebaseConfiguration;
  ios: IOSConfiguration;
  android: AndroidConfiguration;
}


export const environment: Partial<EnvironmentConfiguration> = ({
  built: 1640248355868,
  appVersion: '1.4.52',
  eulaVersion: '1f8a84c73c9bce6c9a06aca496ad1ab11939e4d1',
  environment: 'staging',
  siteOriginWww: 'https://staging-www.seeyamobile.com',
  appengineOrigin: 'https://doorcaptain-staging.uc.r.appspot.com',
  dynamicLinkOrigin: 'https://staging-www.seeyamobile.com/links',
  gcpLocation: 'us-central1',
  sendgridFrom: 'notifications@seeyamobile.com',
  production: true,
  bcc: 'dario@opstrax.com',
  subjectPrefix: '[staging.doorcaptain] - ',
  siteOrigin: 'https://staging-business.seeyamobile.com',
  firebase: {
    apiKey: 'AIzaSyBVR7ji2BQzvcII6ScjGQ989vuvbfdSMWU',
    authDomain: 'doorcaptain-staging.firebaseapp.com',
    databaseURL: 'https://doorcaptain-staging.firebaseio.com',
    projectId: 'doorcaptain-staging',
    storageBucket: 'doorcaptain-staging.appspot.com',
    messagingSenderId: '680396507849',
    appId: '1:680396507849:web:0d964f3099747e7d0bdb18'
  },
  ios: {
    displayName: 'Staging',
    bundleId: 'com.ops.SeeYaStaging',
    provisionalProfile: 'SeeYa Staging Distribution',
    teamId: '5QGN896X23',
    iosAppStoreId: 'id284815942',
    firebaseApiKey: 'AIzaSyAXEnF6itac2HlcpZBvA3ws4j84nuvlOs4',
    firebaseAppId: '1:680396507849:ios:c5e363686227025c0bdb18',
    firebaseClientId: '680396507849-a620lubsnroq7jrfqu9qgpce2lp5ggtp.apps.googleusercontent.com',
    codePushDeploymentKey: 'ulQoRkMcQ6d6XuEH1rJ3WeGWk0hFzTVJkD4WD'
  },
  android: {
    displayName: 'Staging',
    packageName: 'com.ops.SeeYaStaging',
    firebaseAppId: '1:680396507849:android:0807135837ef67120bdb18',
    firebaseClientId: '680396507849-ovlvv057sq4pfjsdhmr14jr03a903up9.apps.googleusercontent.com',
    firebaseApiKey: 'AIzaSyDzV11-ZpA4ii21qYlN21g8NJL65cFqb4U',
    codePushDeploymentKey: 'ObNJZ-paMB9p0_YFGGMnzSykkptxw-ZyhPdpu'
  },
  cloudFunctionOrigin: 'https://staging-business.seeyamobile.com'
} as Partial<EnvironmentConfiguration>);
